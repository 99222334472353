<template>
  <div ref="printMe">
    <Device 
      :device_guid="this.$route.params.id" 
      :device.sync="device" 
      @update="onUpdated"
      @reset="onResetSensor"/>

    <Event ref="eventView" :event_height="80" :device_guid="this.$route.params.id"/>

    <History ref="historyView" :device_guid="this.$route.params.id" :device.sync="device"/>

  </div>
</template>

<script>
import DeviceManage from '@/views/device/DeviceManage'
import Device from '@/views/srds/device/Device'
import Event from '@/views/srds/Event'
import History from '@/views/srds/device/History'

export default {
  name: 'DeviceManageExt',
  extends: DeviceManage,
  components: {
    Device,
    Event,
    History
  },

}
</script>