<!--
<template>
  <CRow>
    <CCol col="12" xl="12">
      <ul class="nav nav-tabs nav-pills">
        <li class="nav-item">
          <a tab class="nav-link" @click.prevent="setActive('tab0')" :class="{ active: isActive('tab0') }" href='#tab0'>측정 이력</a>
        </li>
        <li class="nav-item ">
          <a tab class="nav-link" @click.prevent="setActive('tab1')" :class="{ active: isActive('tab1') }" href='#tab1'>이력 관리</a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane" id='tab0' :class="{ 'active show': isActive('tab0') }">
          <HistoryMeasure
              ref=historyMeasure
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>

        <div class="tab-pane" id='tab1' :class="{ 'active show': isActive('tab1') }">
          <HistoryDevice
              ref=historyDevice
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>
      </div>


    </CCol>
  </CRow>
</template>
-->

<script>
import History from '@/views/device/History';
import HistoryMeasure from '@/views/srds/device/HistoryMeasure';
import HistoryDevice from '@/views/device/HistoryDevice';

export default {
  name: 'HistoryExt',
  extends: History,
  components: {
    HistoryMeasure,
    HistoryDevice
  },
}
</script>