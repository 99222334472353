<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import utils from '@/services/utils';

import Device from '@/views/device/Device'
import DeviceInfo from '@/views/srds/device/DeviceInfo'

export default {
  name: 'DeviceExt',
  extends: Device,
  components: {
    DeviceInfo
  },
  methods: {
    getTableControl() {
      var self = this;
      var getNetworkModule = function(device) {
        var result = "";
        var model = _.get(device, "network_module.model", "-");
        //var version = _.get(device, "network_module.version", "-");
        result += model;
        if (self.$is_mobile)
          result += '<br>'
        //result += " 버전:" + version;
        return result;
      }

      var rows = []
      var device = this.device;
      rows.push({
        "name": "제품 번호",
        "value": _.get(device, "deviceid") + device.guid
      })
      rows.push({
        "name": "설치 일시",
        value: moment(_.get(device, "install_date", "")).format("YYYY-MM-DD HH:mm:ss")
      })
      rows.push({
        "name": "설치 주소",
        "value": _.get(device, "address", "")
      })
      var cron_string = _.get(device, 'upload_cron');
      if (cron_string && cron_string !== "") {
        var cronArray = cron_string.replace(/\s+/g, " ").split(" ");
        if (cronArray.length == 6) {
          cron_string = cronArray.slice(1).join(' ');
        }
        rows.push({
          "name": this.$is_mobile ? "데이터 <br>업로드 주기" : "데이터 업로드 주기",
          "value": this.cronStringFormatter(cron_string)
        })
      } else {
        rows.push({
          "name": this.$is_mobile ? "데이터 <br>업로드 주기" : "데이터 업로드 주기",
          "value": _.get(device, "upload_period")
        })
      }
      var network_type = _.get(device, "network_type", "");
      
      /*rows.push({
        name: "네트웍 타입",
        value: network_type
      })*/
      rows.push({
        name: "최근 측정일시",
        value: moment(_.get(this.sensor, "created_at", "")).format("YYYY-MM-DD HH:mm:ss")
      })
      var data = this.sensor.data;
      if (data && data.length > 0) {
        var eular = {};
        var accle = {};
        var gps_value = {};
        var shock_value;
        for (var i = 0; i < data.length; i++) {
          var name = utils.getSensorTypeName(data[i].type, data[i].id, false);
          var threshold = utils.getThresholdByValue(data[i], this.thresholds);
          //var threshold = undefined;
          var datavalue = data[i].value;

          switch(data[i].type){
            case 1:
              var percentage = 0;
              if(data[i].value > 4) percentage = 100;
              else if(data[i].value > 3.7 && data[i].value <= 4) percentage = 80;
              else if(data[i].value > 3.6 && data[i].value <= 3.7) percentage = 50;
              else if(data[i].value > 3.5 && data[i].value <= 3.6) percentage = 30;
              else if(data[i].value > 3.4 && data[i].value <= 3.5) percentage = 10;
              else if(data[i].value <= 3.4) percentage = 5;
              else percentage = 0;
              datavalue = "<span style='font-weight:bolder'>" + percentage + "% </span>" + "(" + data[i].value + "v)" ;
              rows.push({
                name: "배터리 잔량",
                value: datavalue,
                threshold: threshold
              })              
              break;
            case 3:
              switch(data[i].id){
                case 1:
                  gps_value.lat = data[i].value;
                  break;
                case 2:
                  gps_value.lon = data[i].value;                  
                  break;
                case 3:
                  gps_value.att = data[i].value;                  
                  break;
              }
              break;
            case 8:
              switch(data[i].id){
                case 1:
                  eular.x = data[i].value;
                  break;
                case 2:
                  eular.y = data[i].value;
                  break;
                case 3:
                  eular.z = data[i].value;
                  break;
                case 4:
                  accle.x = data[i].value;
                  break;
                case 5:
                  accle.y = data[i].value;                  
                  break;
                case 6:
                  accle.z = data[i].value;                  
                  break;
              }              
              break;
            case 9:
              shock_value = data[i].value;
              break;
            default:
              if (name && name.length > 0) {
                rows.push({
                  name: name,
                  value: datavalue,
                  threshold: threshold
                })
              }
            break;
          }
        }

        if(shock_value == 1){
          rows.push({
            name: '충격감지',
            value: '감지'
          })        
        }

        var eular_position = '';
        var standard = _.get(device, "standard");
        if (!_.isUndefined(eular.x)) {
          eular_position += 'x축: ' + this.sensorValFormatter(eular.x, standard.x);
        }
        if (!_.isUndefined(eular.y)) {
          eular_position += ' '
          if (self.$is_mobile) {
            eular_position += '<br>'
          }
          eular_position += 'y축: ' + this.sensorValFormatter(eular.y, standard.y);
        }
        if (!_.isUndefined(eular.z)) {
          eular_position += ' '
          if (self.$is_mobile) {
            eular_position += '<br>'
          }
          eular_position += 'z축: ' + eular.z +  "( " + standard.z  + ") " + this.leadingSpaces(eular.z, 10);//this.sensorValFormatter(eular.z, standard.z);
        }        
        if(!_.isEmpty(eular)){
          rows.push({
            "name": "측정 기울기 및 변화값( º)",
            "value": eular_position
          })
        }

        var accle_position = '';
        if (!_.isUndefined(accle.x)) {
          accle_position += 'x축: ' + accle.x + this.leadingSpaces(accle.x, 20);
        }
        if (!_.isUndefined(accle.y)) {
          accle_position += ' '
          if (self.$is_mobile) {
            accle_position += '<br>'
          }
          accle_position += 'y축: ' + accle.y + this.leadingSpaces(accle.y, 20);
        }
        if (!_.isUndefined(accle.z)) {
          accle_position += ' '
          if (self.$is_mobile) {
            accle_position += '<br>'
          }
          accle_position += 'z축: ' + accle.z + this.leadingSpaces(accle.z, 20);
        }
        if(!_.isEmpty(accle)){
          rows.push({
            "name": "가속도 (g)",
            "value": accle_position
          })
        }

        var gps_position = '';
        if (!_.isUndefined(gps_value.lon)) {
          gps_position += '위도: ' + gps_value.lon + this.leadingSpaces(gps_value.lon, 15);
        }
        if (!_.isUndefined(gps_value.lat)) {
          gps_position += ' '
          if (self.$is_mobile) {
            gps_position += '<br>'
          }
          gps_position += '경도: ' + gps_value.lat + this.leadingSpaces(gps_value.lat, 15);
        }
        if(!_.isEmpty(gps_value)){
          rows.push({
            "name": "GPS 센서값",
            "value": gps_position
          })         
        }
        if(!_.isUndefined(gps_value.att)){
          rows.push({
            "name": "해발고도(m)",
            "value": gps_value.att
          })         
        }
      }
      var rows_length = rows.length;
      if (rows_length > 10) {
        rows_length = 10;
        this.options.height = 460;
      }
      this.data = rows;
      this.setupImageStyle(rows_length);
      return;
    },
    leadingSpaces(n, digits) {
      var space = '';
      n = n.toString();

      if (n.length < digits) {
        for (var i = 0; i < digits - n.length; i++)
          space += '&nbsp;';
      }
      return space;
    },   
    sensorValFormatter(value, standard){
      var result = "";
      var thresholds = utils.getThresholds();

      var level0= _.find(thresholds, {sensor_type : 8, level : 0 }).min; //0
      var level2= _.find(thresholds, {sensor_type : 8, level : 2 }).min; //3
      var level3= _.find(thresholds, {sensor_type : 8, level : 3 }).min; //5
      var diff = parseFloat(Number(standard) - value).toFixed(2);
      var span = " ";
      if(diff > level3 || diff <= (level3*(-1))){ 
          span = "<span style = 'color: red; font-weight:bolder'>"      
      }
      else if (diff > level2 || diff <= (level2*(-1))){ 
            span = "<span style = 'color: orange;'>"
      }
      else span = "<span style = 'color: green; '>"
        result += value +  "( " + span + diff + "</span> ) " + this.leadingSpaces(value, 10);

      return result;
    },    
   /* getSensors() {
      var filter = {
        where: {
          device_guid: this.device_guid
        },
        limit: 1,
        order: 'created_at desc'
      }
      this.$store.dispatch('rest/find', {model:'sensors',filter:filter})
        .then((res) => {
          var filter = {
            guid:this.device.site_guid
          }
          this.site = _.find(this.sites, filter)
          this.sensor = _.isEmpty(res) ? res : _.first(res)
          var device = this.device;
          if(!_.isUndefined(this.sensor.data) && !_.isEmpty(this.sensor.data)){
            this.sensor.data.forEach(function(e){
                if(e.type === 3){
                  if(e.id === 1)
                    device.longitude = e.value;
                  else if(e.id === 2)
                    device.latitude = e.value;
                }
            });
          }
          this.getTableControl()
        })
        .catch(err => console.log(err.toString()))
    },*/
    getDeviceImages() {
      var self = this
      this.deviceImages = [];
      var download_url = '{0}/{1}/{2}/download'.format(loopback.defaults.baseURL, this.storage, this.device_guid);
        var filter = {
          where: {
            and: [{
                device_guid: self.device_guid
              },
            ]
          },
          order: 'created_at desc',
          limit: 10,
        }

        self.$store.dispatch('rest/find', {model:'photos',filter:filter})
          .then(function(response) {
            var pushImage = function(info) {
              self.deviceImages.push({
                url: download_url + '/' + info.file_name,
                created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
              })
            }
            for (var i = 0; i < response.length; i++) {
              var info = response[i]
              if (_.isUndefined(info.storage)) continue;
              pushImage(info);
            }
          if (self.deviceImages.length == 0) {
            var network_type = self.device.network_type;
            download_url = 'img/tech9_dev.png'; 
            switch (network_type.toLowerCase()) {
              case 'nb-iot': case 'cat.m1': download_url = 'img/tech9_plug.png'; break; 
              case 'lora': download_url = 'img/tech9_lora.jpg'; break;
            }
            self.deviceImages.push({
              url: download_url
            })
          }
        })
    }
  }
}
</script>